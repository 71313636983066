import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp, MdRateReview } from "react-icons/md";
import ReactSpeedometer from "react-d3-speedometer"

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { Input } from '@material-ui/core';
import savingsRateImg from '../../assets/fire-savings-rate.png'
import Sidebar from '../../components/Sidebar'

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
  }

  h3 {
    font-weight: 500;
  }
`

const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 12px;
  align-items: center;
  margin: 0 0 0px 0;
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SavingsRateDisplay = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
`

const SavingsRateDisplayRed = styled(SavingsRateDisplay)`
  background-color: ${props => props.theme.theme.colors.transparentRed};
  border: 1px solid ${props => props.theme.theme.colors.red};

`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const SpeedometerContainer = styled.div`
  height: 300px;
  max-width: 500px;

  @media (max-width: 600px) {
    height: 200px;
  }

  .segment-value, .current-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }

  .pointer {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

const Image = styled.img`
  width: 100%;
`

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 12px 12px 0;

  h3 {
    /* text-transform: uppercase; */
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const ResultsPanel = styled(AllocationGroup)`
  /* background-color: ${(props) => props.theme.theme.colors.transparentGreen}; */
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 12px;
  display: flex;
  /* grid-template-columns: 1fr; */
  flex-wrap: wrap;
  margin: 12px 0 0 0;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 6px;
  }

  h3 {
    /* color: ${(props) => props.theme.theme.colors.green}; */
    width: 100%;
  }

  @media (max-width: 500px) {
    margin: 0 12px 12px 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ResultsPanelGreen = styled(ResultsPanel)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }
`

const ResultsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 12px 4px 0;

  p {
    font-size: 1rem;
    margin: 8px 0;
  }

  @media (max-width: 500px) {
  }
`;



function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const KeepingUpWithInflationCalc = () => {

  const [ monthlyIncome, setMonthlyIncome ] = useState(5000)
  const [ monthlySpending, setMonthlySpending ] = useState(3000)

  const [ inflationRate, setInflationRate ] = useState(10)
  const [ marginalIncomeTaxRate, setMarginalIncomeTaxRate ] = useState(40)


  const savingsRate =  (monthlyIncome - monthlySpending) / monthlyIncome

  const raiseToKeepUpWithInflation = 100 * ( (inflationRate/100) - ((inflationRate/100) * (savingsRate))) / (1 - (marginalIncomeTaxRate/100))

  const monthlySpendingAfterInflation = monthlySpending * (1 + (inflationRate/100))

  const monthlyIncomeAfterInflation = monthlyIncome * ( 1 + ( (raiseToKeepUpWithInflation/100) * ( 1 - (marginalIncomeTaxRate/100) ) ) )

  const savingsRateAfterInflation = ( monthlyIncomeAfterInflation - monthlySpendingAfterInflation ) / monthlyIncomeAfterInflation

  const incomeToMaintainInitialSavingsRate = monthlySpendingAfterInflation / ( 1 - savingsRate )

  const raiseToMaintainInitialSavingsRate = (( incomeToMaintainInitialSavingsRate - monthlyIncome ) / monthlyIncome) / ( 1 -  (marginalIncomeTaxRate/100) )

  const savingsRateAdjusted = savingsRate > 0 ? savingsRate : 0

  let Dialog

  if(isNaN(savingsRate)){
    Dialog = <SavingsRateDisplay>Please enter your monthly income and spending</SavingsRateDisplay>
  } else {
    if(savingsRate >= 0){
      Dialog =
      <div>


        <ResultsPanel>
            <ResultsGroup>
              <span className="label">Your savings rate: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(savingsRate*100).toFixed(1)}
                  suffix="%"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Your monthly savings: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlyIncome - monthlySpending).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Your monthly spending after inflation: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(monthlySpending * ( 1 + (inflationRate / 100 ))).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>
        </ResultsPanel>

        <ResultsPanelGreen>

          <h3>To keep up with inflation</h3>

          <ResultsGroup>
            <span className="label">Raise needed to keep up with inflation:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(raiseToKeepUpWithInflation).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Monthly take-home pay: </span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={monthlyIncomeAfterInflation.toFixed(0)}
                thousandSeparator
                prefix="$"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Monthly savings: </span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={ (monthlyIncomeAfterInflation - (monthlySpending*( 1 + (inflationRate/100) ) )).toFixed(0)}
                thousandSeparator
                prefix="$"
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Savings rate after {`${(raiseToKeepUpWithInflation).toFixed(1)}%`} raise:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(savingsRateAfterInflation*100).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

        </ResultsPanelGreen>

        <ResultsPanelGreen>
          <h3>To maintain your initial savings rate of {(savingsRate*100).toFixed(1)}%</h3>

          <ResultsGroup>
            <span className="label">Raise needed to maintain initial savings rate:</span>
            <span className="result-value">
              <NumberFormat
                displayType={"text"}
                value={(raiseToMaintainInitialSavingsRate*100).toFixed(1)}
                suffix="%"
              />
            </span>
          </ResultsGroup>

            <ResultsGroup>
              <span className="label">Monthly take-home pay: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={incomeToMaintainInitialSavingsRate.toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Monthly savings: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={ (incomeToMaintainInitialSavingsRate - monthlySpendingAfterInflation).toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>
        </ResultsPanelGreen>
      </div>

    } else{
      Dialog = <SavingsRateDisplayRed>
        Yikes! Your spending exceeds your income.
        You need to lower your spending to spend less than you earn.
      </SavingsRateDisplayRed>
    }
  }

  console.log(savingsRateImg)

  return (
    <Layout>
      <SEO
        title={'Keeping Up With Inflation Calculator'}
        description={'Calculate how much of a salary raise you need to keep up with inflation'}
      />
      <ReactTooltip />

      <CalculatorGrid>
        <form>
          <InputGroup>
            <FlexInput style={{ width: "250px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Annual Inflation Rate"
                  value={inflationRate}
                  onChange={event => setInflationRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp size="24px"
                  data-tip={`This is the annual inflation rate that you personally experience in your spending due to increases in cost of things like rent, food, and fuel costs.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "275px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Marginal Income Tax Rate"
                  value={marginalIncomeTaxRate}
                  onChange={event => setMarginalIncomeTaxRate(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                />
                <MdHelp size="24px"
                  data-tip={`This is the rate of income tax that you pay on every additional dollar that you earn as income.`}
                />
              </InputItem>
            </FlexInput>
          </InputGroup>

        <h3>Before inflation</h3>

        <InputGroup >
          <FlexInput style={{ width: "300px" }}>
            <InputItem>
              <ThemedTextarea
                id="outlined-name"
                label="Monthly take-home pay"
                value={monthlyIncome}
                onChange={event => setMonthlyIncome(event.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <MdHelp size="24px"
                data-tip={`This is your monthly take-home pay after all taxes, before getting a raise.
                This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
              />
            </InputItem>
          </FlexInput>

          <FlexInput style={{ width: "320px" }}>
            <InputItem>
              <ThemedTextarea
                id="outlined-name"
                label="Monthly spending"
                value={monthlySpending}
                onChange={event => setMonthlySpending(event.target.value)}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <MdHelp size="24px"
                data-tip={`This is your total monthly spending before you experience price increases due to inflation.`}
              />
            </InputItem>
          </FlexInput>


        
        </InputGroup>

          {Dialog}

        </form>

      </CalculatorGrid>

    </Layout>
  )
}

export default KeepingUpWithInflationCalc
